//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cookieConsent from '~/mixins/cookieConsent.js'

export default {
  mixins: [cookieConsent],
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.about_us : null
    },
    page () {
      const p0 = this.butterSiteInfo
      if (p0) {
        const p1 = p0.find(item => item.id==='cookie-policy')
        return { title: p1['headline_' + this.lang],
                body: p1['text_' + this.lang]
        }
      } else {
        return { title: '',
                body: ''
        }
      }
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
  },
  mounted () {
    this.isLoading = false
  },
}
